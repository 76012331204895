.policy-main {
    margin: 50px 0 0;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, .125);

    .policy-header {
        background-image: linear-gradient(#edb823a6, #ab832a);
        border-radius: 10px 10px 0 0;
        padding-bottom: 25px;

        .heading {
            font-size: 28px;
            color: #fff;
            padding: 42px 35px;
            font-weight: 600;
            font-family: "Gilda Display", serif;
        }

        .waves {
            position: relative;
            width: 100%;
            height: 16vh;
            margin-bottom: -7px;
            min-height: 100px;
            max-height: 150px;

            .moving-waves {
                use {
                    animation: waves 40s cubic-bezier(.55, .5, .45, .5) infinite;

                    &:first-child {
                        animation-delay: -2s;
                        animation-duration: 11s
                    }

                    &:nth-child(2) {
                        animation-delay: -4s;
                        animation-duration: 13s
                    }

                    &:nth-child(3) {
                        animation-delay: -3s;
                        animation-duration: 15s
                    }

                    &:nth-child(4) {
                        animation-delay: -4s;
                        animation-duration: 20s
                    }

                    &:nth-child(5) {
                        animation-delay: -4s;
                        animation-duration: 25s
                    }

                    &:nth-child(6) {
                        animation-delay: -3s;
                        animation-duration: 30s
                    }


                    @keyframes waves {
                        0% {
                            transform: translate3d(-90px, 0, 0)
                        }

                        to {
                            transform: translate3d(85px, 0, 0)
                        }
                    }

                }
            }
        }
    }

    .policy-body {

        h6 {
            font-size: 17px;
            font-weight: 700;
            margin-bottom: 10px;
        }

        ol {
            li {
                color: #4a4f58;
                font-size: 16px;
                text-align: justify;
                margin-bottom: 10px;
                line-height: 28px;

                span {
                    font-weight: 600;
                }

                p {
                    margin-bottom: 10px;
                }
            }
        }

        ul {
            li {
                line-height: 20px;
            }
        }
    }
}

.contactDetail {
    span {
        font-weight: 700;
        color: #4a4f58;
    }
}

// .payment-policy{
    .ant-modal-content {
        padding: 0!important;
    }
// }