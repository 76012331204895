.contactBanner {
    margin-bottom: 30px;


    .ant-input-affix-wrapper {
        border-radius: 5px;
        padding: 12px 10px;
        width: 100%;
        border: 1px solid #DEDEDE;
        margin-bottom: 0;
        height: 56px;
    }

    .btn-contact {
        background: #bc9132;
        color: #fff;
        border: none;
        padding: 14px 46px;
        height: 48px;

        &:hover {
            background: #bc9132 !important;
            color: #fff !important;
            border: none;
            padding: 14px 46px;
            height: 48px;
        }
    }

    .card {
        margin: 20px;
        padding: 10px;
        border-radius: 3px;
        background: #dbb53e08;

        .box {
            border-radius: 3px;
            padding: 10px;
            border: 1px solid #bc91324d;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            min-height: 225px;

            .icon {
                width: 60px;
                height: 60px;
                border: 1px solid #bc9132;
                border-radius: 50px;
                display: flex;
                justify-content: center;
                align-items: center;

                i {
                    color: #bc9132;
                    font-size: 25px;
                }
            }

            h5 {
                margin-top: 10px;
                font-family: 'emoji';
            }

            p {
                text-align: center;
                line-height: 24px;
                margin-top: 5px;
            }
        }
    }
}

.contact {
    margin-bottom: 30px;

    .contact-form {
        box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 30px 50px 30px 50px;
        background-color: #fff;


        .ant-form-vertical .ant-form-item-label>label,
        .ant-col-24.ant-form-item-label>label,
        .ant-col-xl-24.ant-form-item-label>label {
            font-size: 16px;
            font-weight: 400;
            font-family: "Poppins", sans-serif;
        }

        // label {
        //     font-size: 16px;
        //     margin-bottom: 10px;
        //     font-weight: 500;
        //     font-family: "Poppins", sans-serif;
        // }

        // input,
        // textarea {
        //     border-radius: 5px;
        //     padding: 12px 10px;
        //     width: 100%;
        //     border: 1px solid #DEDEDE;
        //     // margin-bottom: 20px;
        // }

        // .btn {
        //     background: #bc9132;
        //     color: #fff;
        //     border: none;
        //     padding: 14px 46px;
        // }
    }
}

// .field_error {
//     color: #d32f2f;
//     font-size: 0.75rem;
//     text-align: left;
//     margin-top: 3px;
//     margin-right: 14px;
//     margin-bottom: 0;
// }