@import url('https://fonts.googleapis.com/css2?family=Gilda+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;700&display=swap');

.ant-form-item .ant-form-item-explain-error {
    color: #ff4d4f;
    // margin: 18px 0;
}


.ant-picker-outlined {
    background: #ffffff!important;
    border-width: 1px!important;
    border-style: solid!important;
    border-color: transparent!important;
}

.ant-picker-outlined:focus, .ant-picker-outlined:focus-within, .ant-picker-outlined:hover {
    border-color: #fff!important;
    box-shadow: none!important;
    background: transparent!important;
}

.ant-picker-range-separator, .ant-picker-suffix, .ant-picker-clear, .ant-picker-active-bar{
    display: none !important;
}

.ant-picker {
    padding: 0;
    background: transparent!important;
}

.ant-card .ant-card-body {
    padding: 0;
}

