.main-banner {
    width: 100%;
    height: 70vh;
    margin-bottom: 40px;

    .banner-caption {
        color: #fff;
        text-align: center;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        span {
            color: #fff;
            font-size: 25px;
        }

        h1 {
            font-family: 'Niconne', cursive;
            margin-bottom: 20px;
            font-size: 75px;
            text-align: center;
            color: #ffffff;
        }
    }
}

.about-destination {
    margin-top: 20px;
    line-height: 27px;
    text-align: justify;

    // p{
    //     &::first-letter {
    //         font-size: 200%;
    //         color: #000;
    //     }
    // }

    .card {
        margin-bottom: 30px;

        .title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 18px;

            span {
                font-weight: 700;
                color: #6b6262;
            }

            h5 {
                font-size: 17px;
            }
        }

        img {
            border-radius: 5px 5px 0 0;
        }
    }
}

.heading {
    margin-bottom: 20px;

    h3 {
        // font-family: 'Niconne', cursive;
        font-size: 28px;
        &::after {
            position: absolute;
            content: "";
            width: 45px;
            height: 2px;
            border-radius: 5px;
            background-color: #bd9211;
            top: 100%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        
    }
}

.things-to-do {
    position: relative;

    .border-box {
        position: absolute;
        padding: 35px 20px;
        background: #ffffffba;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .title {
            .line {
                margin: 20px auto;
                width: 100px;
                height: 2px;
                background: #000;
            }
        }
    }

    .card {
        border-radius: 0px;
        border: 1px solid rgb(0 0 0 / 14%);
        position: relative;

        &::after {
            position: absolute;
            content: '';
        }

        h6 {
            text-align: center;
            font-size: 23px;
            color: #313131;
            font-weight: bold;
        }

        span {
            font-size: 18px;
            text-align: center;
            display: inherit;
            font-weight: 600;
            color: #313131;
        }

    }

}

.how-to-reach {
    width: 100%;
    background-image: url('../../../public/assets/images/charminar-bg.png');
    background-position: left;
    padding: 100px 0;
    position: relative;

    &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background: #000000f2;
        top: 0;
    }

    .box {
        position: relative;
        z-index: 1;

        .heading{

            h3{
                color: #fff;
            }
        }
    }

    p {
        color: #fff;
        text-align: center;
    }
}

/*.how-to-reach ul{
        position: relative;
        z-index: 2;
        padding: 0;
        display: flex;
    }
        .how-to-reach ul li{
            list-style-type: none;
        }
        .how-to-reach ul li img {
            width: 45px;
            height: 45px;
            margin: auto;
        }*/

.additional-details {
    background: #eeeeee4d;
    padding: 40px 0;

    .box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #e3d7b9;
    }

    .icon {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        border: 2px solid #e3d7b9;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
            color: #c59211;
            font-size: 31px;
        }
    }

    h5 {
        margin-top: 17px;
        font-size: 18px;
        color: #bd9211;
    }

    span {
        font-size: 18px;
        font-weight: 500;
        color: #3f3f3f;
    }
}

.culture {
    margin-bottom: 30px;

    p {
        line-height: 30px;
        text-align: justify;
    }
}
