@media (min-width: 768px) {

    .container,
    .container-md,
    .container-sm {
        max-width: 920px;
    }
}

@media (min-width: 880px) {

    .container,
    .container-md,
    .container-sm {
        max-width: 1120px;
    }
}

@media (min-width: 1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1140px;
    }
}

.main {
    background-color: #fffdf8;

    .search-bar {
        padding: 20px 0 30px;

        label {
            margin-bottom: 15px;
            font-size: 16px;
            font-weight: 500;
            color: #3c454e;
            margin-bottom: 5px;
        }

        .ant-picker-input,
        .select-acr {
            padding: 8px 46px 8px 16px;
            border: 1px solid #c7c7c7;
            border-radius: 4px;
            width: 100%;
            // margin-top: 10px; 
        }

        .ant-picker .ant-picker-input>input {
            color: #082a4b;
            font-size: 18px;
            font-weight: 500;
            font-family: "Barlow";
        }

        .ant-picker-input-active,
        .ant-popover-open {
            border-color: #2686b0;
        }

        .ant-picker-input:nth-child(1) {
            margin-right: 25px;
        }

        .select-acr i {
            font-size: 26px;
        }

        .modify-btn {
            background: #d8a306;
            border: none;
            color: #fff;
            width: 100%;
            max-width: 150px;
            border-radius: 3px;
            font-size: 18px;
            font-family: "Barlow";
            font-weight: 600;
            height: 45px;

            i {
                margin-top: 8px;
            }

            &:disabled,
            &.ant-btn-disabled {
                cursor: not-allowed;
                border-color: #d9d9d9;
                color: rgba(0, 0, 0, 0.25);
                background: rgba(0, 0, 0, 0.04);
                box-shadow: none;

                &:hover,
                &:focus {
                    background: rgba(0, 0, 0, 0.04) !important;
                    border-color: #d9d9d9;
                    color: rgba(0, 0, 0, 0.25);
                }
            }

            &:not(:disabled):hover,
            &:not(:disabled):focus {
                background: darken(#d8a306, 10%);
            }
        }
    }

}

.searchResult {
    padding: 40px 0;

    .hotel-name {
        font-weight: 700;
        color: #d8a306;

        span {
            color: #303030;
        }
    }

    .inner-card-12 {
        .card {
            margin: 15px 0;
            border: 1px solid #cecece;
            overflow: hidden;

            .hotel-room-img {
                height: 100%;
                max-height: 270px;
                float: left;

                @media (max-width: 770px) {
                    &.hotel-room-img {
                        float: none;
                        width: 100%;
                    }
                }

                @media (max-width: 993px) and (min-width: 990px) {
                    & {
                        max-height: 160px;
                    }
                }

                @media (max-width: 990px) and (min-width: 977px) {
                    & {
                        max-height: 190px;
                    }
                }

                @media (max-width: 977px) and (min-width: 790px) {
                    & {
                        max-height: 190px;
                    }
                }

            }

            .content {
                // padding: 43px 10px;
                border-right: 2px dashed #ddddddcc;

                h3 {
                    font-size: 21px;
                    font-weight: 600;
                    // font-family: $title-font;
                }

                span {
                    font-size: 16px;
                    color: #727171;
                    display: inline-block;
                }

                .des {
                    font-size: 15px;
                }

                .amenities,
                .facilities {
                    margin-top: 5px;

                    img {
                        width: 22px;
                        margin-right: 10px;
                    }

                    p {
                        margin-bottom: 0px;
                        font-size: 16px;
                        font-weight: 500;
                    }

                    ul {
                        padding-left: 0;
                        margin-bottom: 0;

                        li {
                            list-style-type: none;
                            font-size: 14px;
                            line-height: 18px;
                            color: #0d0d0d;
                            display: inline-block;
                            margin-top: 7px;
                            margin-right: 8px;
                        }
                    }
                }


                @media (max-width: 992px) {
                    .facilities p {
                        margin-top: 25px;
                    }
                }
            }

            .rate-book-info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .rate {
                    font-size: 22px;
                    text-align: center;
                    font-weight: 600;
                    color: #0c2844;

                    i {
                        font-size: 22px;
                        font-weight: 600;
                    }
                }

                .form-select {
                    border-radius: 2px;
                    margin-bottom: 4px;
                    border: 1px solid #d8a306;
                    padding: 10px;
                    width: 148px;
                    background-color: #d8a30608;
                }

                .btn {
                    background: #1d7ca3;
                    border-radius: 0px;
                    padding: 12px 40px;
                    color: #fff;
                    font-weight: 500;
                    margin-top: 5px;
                }
            }

            &:hover {
                border: 1px solid #1f87b0;
            }
            .offer_strip {
                position: absolute;
                top: 30px;
                right: -42px;
                rotate: 45deg;
                padding: 2px 25px;
                font-weight: bold;

                &2 {
                    background: red;
                    color: white;
                }
            }
            .tag_room {
                position: relative;
                background: #f01b3b !important;
                border: none !important;
                &::before {
                    content: "";
                    position: absolute;
                    bottom: 0px;
                    right: -17px;
                    border-bottom: solid 10px #f01b3b;
                    border-left: solid 10px #f01b3b;
                    border-right: solid 10px transparent;
                    border-top: solid 10px transparent;
                }
                &::after {
                    content: "";
                    position: absolute;
                    top: 0px;
                    right: -17px;
                    border-top: solid 10px #f01b3b;
                    border-left: solid 10px #f01b3b;
                    border-right: solid 10px transparent;
                    border-bottom: solid 10px transparent;
                }
            }
        }


        @media screen and (max-width: 576px) {
            .card {
                .hotel-room-img {
                    padding: 8px;
                }

                .content {
                    // padding: 10px;
                    border-right: none;
                    border-bottom: 2px dashed #ddddddcc;


                    h3 {
                        font-size: 20px;
                    }

                    span {
                        font-size: 15px;
                    }

                    .des {
                        font-size: 13px;
                    }

                    .amenities,
                    .facilities {
                        img {
                            width: 25px;
                        }

                        p {
                            font-size: 14px;
                        }

                        ul {
                            li {
                                font-size: 13px;
                            }
                        }
                    }
                }

                .rate-book-info {
                    display: block;
                    width: 100%;
                    margin: 20px 0 0;

                    .rate,
                    .ant-select {
                        margin-bottom: 0 !important;
                        margin-right: 0px;
                        font-size: 25px;
                    }
                }
            }
        }

        @media screen and (max-width: 770px) {
            .card {
                .content {
                    border-right: none;
                    border-bottom: 2px dashed #ddddddcc;
                }
            }
        }

    }



    .inner-card-8 {
        .card {
            margin: 15px 0;
            border: 1px solid #cecece;

            .hotel-room-img {
                padding: 8px;
                height: 100%;
                max-height: 308px;
                // width: 260px !important;
                // height: 337px;
            }

            .content {
                padding: 20px 10px;
                border-right: 2px dashed #ddddddcc;

                h3 {
                    font-size: 19px;
                    font-weight: 600;
                    // font-family: $title-font;
                }

                span {
                    font-size: 14px;
                    color: #727171;
                    font-weight: 500;
                    display: inline-block;
                }

                .des {
                    font-size: 15px;
                }

                .amenities,
                .facilities {
                    margin-top: 5px;

                    img {
                        width: 20px;
                        margin-right: 2px;
                    }

                    p {
                        margin-bottom: 0px;
                        font-size: 16px;
                        font-weight: 500;
                    }

                    ul {
                        padding-left: 0;
                        margin-bottom: 0;

                        li {
                            list-style-type: none;
                            font-size: 13px;
                            line-height: 18px;
                            color: #0d0d0d;
                            display: inline-block;
                            margin-top: 7px;
                            margin-right: 6px;
                        }
                    }
                }

            }



            .rate-book-info {
                display: flex;
                flex-direction: column;
                margin-right: 12px;

                .rate {
                    font-size: 24px;
                    text-align: center;
                    font-weight: 700;
                    color: #0c2844;
                    margin-bottom: 14px;

                    i {
                        font-size: 21px;
                        font-weight: 600;
                    }
                }

                .select-amount {
                    .ant-select {
                        margin-bottom: 0;
                        padding: 0;
                    }
                }

                .ant-select .ant-select-arrow {
                    top: 17px;
                    right: 10px;
                    width: 12px;
                    height: 12px;
                }
            }

            .sold-out {
                padding: 10px 20px;
            }

            &:hover {
                border: 1px solid #1f87b0;
            }

            @media screen and (max-width: 768px) {
                .hotel-room-img {
                    padding: 8px;
                }

                .content {
                    padding: 10px;
                    border-right: none;
                    border-bottom: 2px dashed #ddddddcc;


                    h3 {
                        font-size: 20px;
                    }

                    span {
                        font-size: 15px;
                    }

                    .des {
                        font-size: 13px;
                    }

                    .amenities,
                    .facilities {
                        img {
                            width: 25px;
                        }

                        p {
                            font-size: 14px;
                        }

                        ul {
                            li {
                                font-size: 13px;
                            }
                        }
                    }
                }

                .rate-book-info {
                    flex-direction: row;
                    justify-content: space-evenly;
                    align-items: center;
                    margin-bottom: 0;
                    margin: 20px 0;

                    .rate,
                    .ant-select {
                        margin-bottom: 0 !important;
                        margin-right: 20px;
                        font-size: 25px;
                    }
                }
            }
        }
    }
}

.book-room-info {
    width: 100%;
    background: #1d7ca3;
    padding: 30px 20px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 16;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1803921569);

    .book-room-amount {
        display: flex;
        justify-content: center;
        align-items: center;

        .room-info {
            padding: 0 20px;
            border-right: 1px solid #eeeeee29;
            display: flex;
            align-items: center;


            h6 {
                font-size: 20px;
                color: #f6f6f6;
                line-height: 28px;

                .roomFor {
                    color: #efefef;
                    font-size: 16px;
                    font-weight: 400;
                }
            }

            .total-rate {
                font-size: 26px;
                font-weight: 700;
                color: #fff;
                margin-left: 30px;
            }

            .btn {

                color: #fff;
                margin-left: 30px;
                background: #d8a306;
                font-weight: 700;
                padding: 5px 34px;
                border-radius: 3px;
                height: 55px;
                margin-top: 3px;
            }
        }

    }

    @media screen and (max-width: 768px) {
        padding: 20px;

        .book-room-amount {
            flex-direction: column;

            .room-info {
                padding: 0;
                border-right: none;
                width: 100%;
                justify-content: center;

                h6 {
                    line-height: 25px;
                    margin-bottom: 0;
                    font-size: 18px;

                }

                span {
                    color: #fff;
                }

                .total-rate {
                    color: #fff;
                    font-size: 20px;
                }

                .total-amm {
                    font-size: 16px;
                }

                .btn {

                    padding: 0 15px;
                    height: 45px;
                }
            }
        }
    }
}


.select-amount {
    .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
        border: 1px solid #d9d9d9;
        background: #ffffff;
        border-radius: 2px;
        margin-bottom: 4px;
        border: 1px solid #d8a306;
        // padding: 25px; */P
        border-radius: 4px;

        // width: 148px;
        background-color: rgba(216, 163, 6, 0.031372549);
    }
}


.ant-card .ant-card-body,
.ant-card-bordered {
    border: none;
}

.popup-form {
    .ant-form-item {
        margin-bottom: 0 !important;
    }
}

.sold-out {
    background: rgb(255, 0, 0);
    color: #fff;
    font-weight: 500;
    padding: 10px 25px;
    border-radius: 5px;
}

.customize-date {
    .ant-picker-outlined {
        background: #ffffff !important;
        border-width: 1px !important;
        border-style: solid !important;
        border-color: transparent !important;
    }

    .ant-picker-outlined:focus,
    .ant-picker-outlined:focus-within,
    .ant-picker-outlined:hover {
        border-color: #fff !important;
        box-shadow: none !important;
        background: transparent !important;
    }

    .ant-picker {
        padding: 0;
    }
}




.show_hotel_details {
    position: absolute;
    top: 45%;
    left: -6px;
    border: none;
    padding: 6px 0 6px 0;
    border-radius: 5px 0 0 5px;
    background: #d8a306;
    color: white;
    cursor: pointer;
    z-index: 2;


    &:hover {
        color: white;
    }
}

.hotel_details,
.hotel_details_show {
    position: fixed;
    top: 6%;
    right: 0;
    z-index: 1;
    width: 305px;

}

.hotel_details {
    right: -290px;
}

.hotel_details_show {
    right: 0;
    z-index: 9999;
}

.booking-details {
    background: #fffdf8 !important;
    border: 1px solid #ccc;
    border-right: 0;
    border-radius: 10px 0 0 10px !important;
    // margin-top: 54px !important;
    padding: 15px !important;
    min-width: 290px !important;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.room-info {
    h6 {
        margin-bottom: 0 !important;
    }

    button {
        padding: 8px !important;
    }
}

@keyframes typing {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

.typewriter h3 {
    overflow: hidden;
    border-right: .15em solid orange;
    white-space: nowrap;
    margin: auto;
    animation: typing 20s steps(100) 1;
}

.book-now-btn {
    margin-top: 10px;
    color: #fff;
    font-size: 16px;
    padding: 15px 16px;
    background: #09205c;
    text-align: center;
    border-radius: 5px;
}

.total-amount {
    margin: 18px 0;
    font-size: 19px;
    color: #09205c;
}

.room-for {
    margin-top: 10px;
    color: #757575;
}

.heading-booking {
    font-size: 18px;
    color: #222121;
}




.image-gallery {
    display: flex;
    flex-direction: column;
}


.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
    font-size: inherit;
    color: #1d7ca3;
}



.custom-carousel {

    .slick-prev {
        left: 0;
    }

    .slick-next {
        right: 0;
    }

    .slick-slide {
        img {
            width: 100%;
            height: auto;
            border-radius: 8px;
        }
    }
}

.custom-carousel {
    .slick-dots li {
        height: 24px;
    }

    .slick-dots {
        li {
            button {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: rgb(245 106 0) !important;
                border: none;

                &.slick-active {
                    background-color: rgb(245 106 0) !important;
                }
            }
        }
    }
}

.number_of_guest {
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 500;
    color: #000 !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled) .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled) .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
    color: #fff;
    background: #d8a306;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid #d8a306;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range:not(.ant-picker-cell-disabled):before,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled):before,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled):before {
    background: #e0d4aebd;
}



.room_title {
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 500;
}

.rate-book-info {
    .rate-offer {
        font-size: 14px;
        color: rgb(119, 119, 119);
        text-decoration: line-through;
    }
}

.tag_room {
    color: #ffffff !important;
    background: #e63148 !important;
    border-color: #e63148 !important;
    font-weight: 700;
    font-size: 18px !important;
}

.couple-svg {

    img,
    svg {
        vertical-align: middle;
        font-size: -5px;
        width: 20px;
        cursor: pointer;
    }
}


// .searchResult .inner-card-12 .card .content .facilities ul li {
//     font-size: 13px;
//     margin-right: 5px;
//     margin-top: 0px;
// }

// .searchResult .inner-card-12 .card .content .amenities ul li {
//     font-size: 13px;
//     margin-right: 5px;
//     margin-top: 0px;
//     padding: 2px 5px;
//     background: rgba(3, 30, 255, 0.05);
//     border-radius: 4px;
// }

// .searchResult .inner-card-12 .card .content .amenities {
//     margin-bottom: 10px;
// }

// .searchResult .inner-card-12 .card .content .amenities img,
// .searchResult .inner-card-12 .card .content .facilities img {
//     margin-right: 6px;
//     width: 16px;
//     filter: invert(1);
// }

// .searchResult .inner-card-12 .card .content span {
//     color: #727171;
// }


