.print-container {
    padding: 20px;
    // background-color: #f0f2f5;
}

.booking-container {
    width: 75%;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: none;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 42px 0;
}

.header img {
    width: 200px;
}

.booking-number {
    margin-top: 15px;
    font-size: 16px;
    font-weight: bold;
    color: #007bff;
}

.section-title {
    font-size: 18px;
    font-weight: bold;
    color: #007bff;
    margin-bottom: 15px;
    border-bottom: 2px solid #007bff;
    padding-bottom: 5px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

table,
th,
td {
    border: 1px solid #e0e0e0;
}

th,
td {
    padding: 12px;
    text-align: left;
}

th {
    background-color: #f8f9fa;
    color: #333;
}

td {
    font-size: 16px;
    color: #555;
}

.amount-paid {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-top: 20px;
    padding: 15px;
    border-radius: 8px;
    background-color: #f8f9fa;
    // box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
}

.footer {
    margin-top: 30px;
    font-size: 14px;
    text-align: center;
    color: #666;
}

.footer span {
    font-weight: bold;
    color: #555;
}