.preloader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .loader {
        width: 58px;
        height: 58px;
        border-radius: 50%;
        display: inline-block;
        border-top: 6px solid rgb(29 124 163);
        border-right: 6px solid transparent;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;

        &:after {
            content: '';
            box-sizing: border-box;
            position: absolute;
            left: 0;
            top: 0;
            width: 58px;
            height: 58px;
            border-radius: 50%;
            border-left: 6px solid #0c2844;
            border-bottom: 6px solid transparent;
            animation: rotation 0.5s linear infinite reverse;
        }

    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }


    .text {
        margin: 10px;
        font-weight: 500;
        font-size: 18px;
    }
}