.mainSearch {
    padding: 28px 0 20px;
    display: flex;
    flex-direction: column;

    label {
        margin-bottom: 15px;
        font-size: 18px;
        font-weight: 500;
        margin-left: 10px;
        color: #3c454e;

        @media screen and (max-width: 576px) {

            margin-bottom: 10px;
            font-size: 15px;
        }

        @media screen and (max-width: 820px) {

            margin-bottom: 10px;
        }
    }

    .ant-picker {
        padding: 0 !important;
    }

    .ant-picker-input,
    .select-acr {
        padding: 12px;
        // border: 2px solid #dbdbdbee;
        border: 1px solid #878888;
        border-radius: 4px;

        @media screen and (max-width: 820px) {
            padding: 8px 13px;
            margin-bottom: 15px;
        }
    }

    .ant-picker-input:nth-child(1) {
        margin-right: 25px;
    }

    .ant-picker .ant-picker-input>input {
        color: #082a4b;
        font-size: 22px;
        font-weight: 700;
        font-family: "Barlow";

        @media screen and (max-width: 576px) {

            font-size: 18px;
        }
    }

    .select-acr {
        padding: 14px 16px;
        display: flex;
        justify-content: space-between;
        // margin-top: 4px;
        font-weight: 700;
        font-size: 20px;
        font-family: 'Barlow';

        @media screen and (max-width: 576px) {

            font-size: 18px;

            padding: 8px 13px;
        }
    }

    .ant-picker-input-active,
    .ant-popover-open {
        border-color: #2686b0;
    }

    .search-room {
        background: #d8a306;
        border: none;
        color: #fff;
        width: 100%;
        border-radius: 5px;
        font-size: 18px;
        font-family: 'Barlow';
        font-weight: 600;
        padding: 17px 0;
        margin-top: 40px;

        @media screen and (max-width: 820px) {

            margin-top: 10px;
            font-size: 17px;
            padding: 15px 0;
        }

        @media screen and (max-width: 820px) {

            font-size: 19px;
        }
    }

    .input-group-text {
        background: #78787842;
        border: 1px solid #929292ee;
        color: #504e4e;
        font-size: 18px;
    }

    .form-control:disabled {
        background-color: #ffffff;
    }

    .select-acr i {
        font-size: 26px;
    }
}

.ant-popover .ant-popover-inner {
    padding: 0px;
}

.popup-inner {
    padding: 16px 28px 0;
    width: 370px !important;

    .selection {
        padding-left: 60px;
    }

    .select-adult,
    .select-child,
    .select-room {

        display: flex;
        justify-content: space-between;
        align-items: center;
    }


}

.popup-footer {
    padding: 16px 28px;
    display: flex;
    justify-content: flex-end;
    background: #ffffff;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
    border-radius: 0 0 4px 4px;

    .apply-btn {
        background-color: #d8a306;
        color: #fff;
        border-radius: 5px;
        padding: 20px 0;
        border: none;
        float: right;
        width: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        font-weight: 600;

        &:hover {
            span {
                color: #fff;
            }
        }
    }

    .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
        background-color: #d8a306;
        color: #fff;
    }
}

// css for book now page 
.booknow {
    margin-bottom: 30px;

    .field_error {
        color: #d32f2f;
        font-size: 0.75rem;
        text-align: left;
        margin-top: 3px;
        margin-right: 14px;
        margin-bottom: 0;
    }

    .hotel-name {
        font-weight: 700;
        color: #d8a306;

        span {
            color: #303030;
        }
    }

    .delux-room-heading {
        margin-top: 20px;
    }

    // .contact-form {
    //     box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
    //     border-radius: 10px;
    //     padding: 30px 50px 30px 50px;
    //     background-color: #fff;

    //     label {
    //         font-size: 16px;
    //         margin-bottom: 10px;
    //         font-weight: 500;
    //         margin-top: 20px;
    //         font-family: "Poppins", sans-serif;
    //     }

    //     input {
    //         border-radius: 5px;
    //         padding: 12px 10px;
    //         width: 100%;
    //         border: 1px solid #DEDEDE;
    //         margin-bottom: 0;
    //     }

    // }

    .btn-box {
        .btn {
            background: #bc9132;
            color: #fff;
            border: none;
            padding: 14px 46px;
            margin-top: 20px;
            height: 56px;
        }
      .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
            border-color: #bc9132;
            background: #bc9132;
            color: #fff;
        }
    }
}

.checkbox-input {
    input[type="checkbox"] {
        margin-right: 5px;
        width: auto;
    }

    label {
        margin-top: 0 !important;
    }
}

.heading {
    h1 {
        font-family: "Gilda Display", serif;
        text-align: center;
        color: #000;
        font-size: 30px;
        font-weight: 600;
        letter-spacing: 1px;
    }
}




.searchBarOverlay {
    position: absolute;
    top: 85px;
    background: #fff;
    border-radius: 0px;
    max-height: 225px;
    box-shadow: 0px 0px 10px #0000002e;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 15px 15px;
    width: 100%;
    z-index: 99;
    border-radius: 8px 0 0 8px;
    &::-webkit-scrollbar {
        width: 2px;
        background-color: #F5F5F5;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #0d10ca;
        border: 2px solid #555555;
    }

    // border-bottom-left-radius: 30px;
    // border-bottom-right-radius: 30px;
    .country-list {
        cursor: pointer;
    }
    ul {
        padding-left: 0;
        margin-bottom: 0;
    }
}

.mainSearch {
    .select-amount .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
        border: 1px solid #d9d9d9;
        background: #ffffff;
        border-radius: 2px;
        margin-bottom: 4px;
        border: 1px solid #d8a306;
        padding: 10px;
        // width: 148px;
        padding: 30px 12px;
        background-color: rgba(216, 163, 6, 0.031372549);
    }


}


// .select-child {}
.contact-form {
    .ant-select-single {
        font-size: 14px !important;
        height: 50px !important;
        width: 100% !important;
    }
}

.price-breakup {
    .box {
        position: relative;
        top: -8px;
    }
}

// .dial-code {
//     .ant-select-selector {
//         background: #ffff !important;
//         color: #000 !important;
//     }
// }