$secondary-font: 'Gilda Display',
    serif;
$primary-font: 'Barlow';
$color-theme1: #09205c;
$color-theme2: #ffff;


@mixin heading {
    text-align: center;

    .sub-title {
        font-size: 15px;
        font-weight: 400;
        margin: 0;
        font-family: $primary-font;
        letter-spacing: 3px;
        line-height: 29px;
        margin-bottom: 6px;
        text-transform: uppercase;


        @media screen and (max-width: 820px) {
            text-align: center;
        }
    }

    .title {
        margin: 15px 0 0;
        font-size: 40px;
        font-weight: bold;
        font-family: $secondary-font;



        @media screen and (max-width: 1400px) {
            font-size: 35px;

        }

        @media screen and (max-width: 768px) {
            font-size: 26px;
            text-align: center;
            margin: 8px 0 0;
        }

        @media screen and (max-width: 820px) {
            font-size: 27px;
            text-align: center;
        }
    }
}

.heading {
    h3 {
        font-family: $secondary-font;
        text-align: center;
        // color: #bd9211;
        color: #5e0605cf;
        position: relative;
        font-size: 33px;
        font-weight: 600;
        letter-spacing: 1px;

        &:after {
            position: absolute;
            content: '';
            width: 45px;
            height: 4px;
            border-radius: 5px;
            background-color: #bd9211;
            top: 100%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.strip {
    margin: 20px 0;
    padding: 20px 0;
    // background: linear-gradient(180deg, rgb(31 115 149) 0%, rgb(29 124 163) 56%) !important;
    background: linear-gradient(180deg, #2098c9 0%, #12749c 56%) !important;
    color: #fff;
    text-align: center;
    border-radius: 5px;

    h3 {
        font-size: 21px;
        font-weight: 700;
        letter-spacing: 0.9px;
    }
}

.roomSlider {
    margin: 35px 0 100px;

    .carousel-item {
        overflow: hidden;
    }
}

.about-us {
    margin-bottom: 100px;

    .about {

        .main-heading {
            @include heading;
            text-align: left;
        }

        .content {
            span {
                font-family: $primary-font;
                font-size: 24px;
                font-weight: 400;
                margin: 15px 0;
                display: inline-block;
            }

            p {
                font-size: 17px;
                text-align: justify;
                line-height: 32px;
            }

            ul {
                padding-left: 0;

                li {
                    font-size: 17px;
                    font-weight: 500;
                    color: #3e3c3c;
                    list-style-type: none;
                    display: flex;
                    align-items: center;
                    margin-bottom: 22px;


                    .icon {
                        width: 65px;
                        height: 65px;
                        border: 1px solid #d8a306;
                        border-radius: 50px;
                        margin-right: 18px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            width: 40px;
                            height: 40px;
                        }
                    }
                }
            }

            .other-info {
                .location {
                    max-width: 60%;
                }

                .contact-info {
                    max-width: 40%;
                }

                .location,
                .contact-info {
                    width: 100%;
                    margin-right: 20px;


                    span {
                        img {
                            width: 30px;
                            height: 30px;
                        }

                        i {
                            font-size: 15px;
                        }

                        font-size: 15px;
                        text-align: justify;
                        line-height: 28px;
                    }
                }
            }
        }
    }

    .about-img {
        border-radius: 300px 0 0 300px;
    }

    @media screen and (max-width: 1400px) {
        .about {
            .content {
                span {
                    font-size: 20px;
                }

                p {
                    font-size: 15px;
                    line-height: 30px;
                }
            }
        }
    }
}

.room-accommodation {
    margin-bottom: 60px;
    padding: 80px 0px 80px 0px;
    background-color: $color-theme1;

    .main-heading {
        @include heading;
        color: #fff;
    }

    .item {
        padding: 10px;
        margin-top: 20px;
    }

    .room-content {
        img {
            transition: all 0.5s ease;
            -moz-transition: all 0.5s ease;
            -webkit-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
            border-radius: 5px;
            width: 100%;
        }

        &:hover {
            img {
                transition: all 0.5s ease;
                -moz-transition: all 0.5s ease;
                -webkit-transition: all 0.5s ease;
                -o-transition: all 0.5s ease;
                transform: translateY(-10px);
            }
        }
    }

    .room-info {

        ul {
            padding-left: 20px;
            margin: 20px 0;
            display: flex;
            align-items: center;
            justify-content: center;

            li {
                color: #fff;

                &:not(:last-child) {

                    margin-right: 35px;
                }
            }
        }

        .room-title {
            font-family: $secondary-font;
            text-align: center;
            color: #fff;
            font-size: 25px;

            &:hover {
                font-weight: bold;
            }
        }

        .room-price {
            color: #fff;
            text-align: center;

            .price {
                color: #bd9211;
                font-size: 30px;
                font-weight: 600;
            }
        }
    }

}


.services {
    margin-bottom: 60px;


    .main-heading {

        @include heading;
    }

    .card {
        padding: 29px 22px 28px 30px;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 20px;

        .content {
            margin-right: 15px;

            h3 {
                font-family: $secondary-font;
                margin-bottom: 10px;
            }

            p {
                margin-bottom: 0;
                font-size: 17px;
            }
        }

        img {
            width: 85px;
        }
    }
}

.location {
    .main-heading {

        @include heading;
    }

    .item {
        margin: 8px;
        width: auto !important;
    }

    .card {
        border: 4px solid #eee;
        border-radius: 0px;
        padding: 8px;

        .img-box {
            overflow: hidden;

            img {
                transition: 0.3s all;
                border-radius: 3px;
            }

            &:hover {
                img {
                    transform: scale(1.1);
                }
            }
        }

        .text-box {
            display: flex;
            flex-direction: column;
            padding: 20px 15px;

            h3 {
                font-family: $secondary-font;
                font-size: 21px;
                font-weight: 600;
                letter-spacing: 0.3px;
            }

            p {
                line-height: 24px;
                font-size: 15px;
                text-align: justify;
                overflow-y: hidden;
                height: 100%;
                max-height: 95px;
            }

            a {
                color: #bd900b;
                text-decoration: none;
                font-weight: 600;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                i {
                    font-size: 19px;
                    margin-top: 5px;
                }
            }
        }
    }
}

.home-page {
    .ant-carousel .slick-dots-bottom {
        bottom: -20px !important;
    }

    .ant-carousel .slick-dots li button {
        width: 15px !important;
        height: 5px !important;
        margin: 0 3px !important;
        background: #dadada !important;
        display: block !important;
        transition: opacity 0.2s ease !important;
        border-radius: 2px !important;
    }

    .ant-carousel .slick-dots li.slick-active {
        margin-right: 10px !important;
    }

    .ant-carousel .slick-dots li.slick-active button {
        background: #bd900b !important;
        width: 30px;
    }
}


// Book Now
.bg-main {
    width: 100%;
    padding: 80px 0;
    background: linear-gradient(180deg, #2098c9 0%, #12749c 56%) !important;

}

.booknow {
    margin-bottom: 30px;
    position: relative;
    top: -120px;

    h2 {
        font-family: $primary-font;
        font-weight: 500;
        color: #fff;
        margin-bottom: 35px;
        font-size: 28px;
    }

    .room-detail,
    .price-breakup,
    .contact-form {
        box-shadow: 0 1px 8px #0000002e;
        border-radius: 4px;
        background-color: #fff;

        .date {
            border-bottom: 1px dotted #ccc;
            background-color: #f2f2f266;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 25px;

            .seprate-border {
                width: 3px;
                height: 100px;
                background: #ffff;
            }

            h5 {

                line-height: 30px;

                span {
                    font-size: 15px;
                    color: #4a4a4a;
                }
            }
        }


        .content {
            padding: 20px 25px 30px;

            .room-type {
                font-family: $primary-font;
                font-size: 23px;
                font-weight: 600;
                color: #635b5b;

                @media screen and (max-width: 576px) {
                    font-size: 18px;
                }
            }

            ul {
                // padding-left: 15px;
                // font-size: 15px;
                // line-height: 24px;
                // margin-bottom: 0px;
                padding-left: 0;
                margin-bottom: 0;

                li {
                    font-size: 15px;
                    line-height: 18px;
                    color: #0d0d0d;
                    display: inline-block;
                    margin-top: 7px;
                    margin-right: 10px;
                }
            }
        }

        .imptInfo {
            background-color: #fff8ed;
            border-top: 1px dashed #ffc4c2;
            padding: 12px 25px;
            border-radius: 0 0 4px 4px;

            h6 {
                color: #cf8100;
                font-size: 17px;
                font-weight: 700;
            }

            ul {
                padding-left: 15px;

                li {
                    font-size: 14px;
                }
            }
        }

        // label {
        //     font-size: 16px;
        //     margin-bottom: 10px;
        //     font-weight: 500;
        //     margin-top: 20px;
        //     font-family: "Poppins", sans-serif;
        // }

        // input {
        //     border-radius: 5px;
        //     padding: 12px 10px;
        //     width: 100%;
        //     border: 1px solid #DEDEDE;
        //     margin-bottom: 0;
        // }

    }

    .price-breakup {
        padding: 25px;

        .amount {
            font-family: $primary-font;
            font-size: 20px;
            font-weight: 600;
        }

        @media screen and (max-width: 1199px) {

            padding: 20px;
        }
    }

    .contact-form {

        padding: 25px;

        .form-heading {
            font-family: $primary-font;
            font-size: 20px;
            font-weight: 600;
        }

        label {
            font-size: 16px;
            // margin-bottom: 10px;
            font-weight: 500;
            font-family: $primary-font;
        }

        // input,
        // select {
        //     border-radius: 5px;
        //     padding: 12px 10px;
        //     width: 100%;
        //     border: 1px solid #DEDEDE;
        //     margin-bottom: 0;
        // }

        .ant-input-affix-wrapper {
            border-radius: 5px;
            padding: 12px 10px;
            width: 100%;
            border: 1px solid #DEDEDE;
            margin-bottom: 0;
            height: 51px;
        }

        .ant-input-outlined {
            border-radius: 5px;
            padding: 12px 10px;
            width: 100%;
            border: 1px solid #DEDEDE;
            margin-bottom: 0;
            height: 51px;
        }

        .checkbox-input {
            input[type="checkbox"] {
                margin-right: 5px;
                width: auto;
            }

            label {
                margin-top: 0 !important;
            }
        }

        @media screen and (max-width: 768px) {

            padding: 20px;

            input,
            select {
                border-radius: 5px;
                padding: 8px 10px;
            }

            .ant-select-single {
                height: 40px !important;
                width: 100% !important;
            }

            .btn {
                padding: 12px 22px;

            }
        }
    }

    @media screen and (max-width: 768px) {

        h2 {
            font-size: 22px;
            margin-bottom: 20px;
        }

        .room-detail {
            .date {
                padding: 6px 20px;

                h5 {
                    text-align: center;
                }
            }

            .content {
                padding: 20px 20px 30px;

                ul {
                    li {
                        line-height: 10px;
                    }
                }
            }

            .imptInfo {
                padding: 12px 20px;
            }
        }
    }
}

.review-booking-modal {

    .icon {
        color: #000;
        font-size: 22px;
    }

    h4 {
        font-size: 21px;
    }

    .room-details,
    .guest-details {

        .date,
        .guest-detail-heading {
            width: 100%;
            background-color: #1d7ca3;
            border-radius: 4px;
            color: #fff;
            display: flex;
            padding: 12px 20px;

            h5 {
                margin-bottom: 0;
                font-size: 16px;
            }
        }

        .content {
            width: 100%;
            padding: 20px 15px;
            margin: 8px 0 20px;
            border: 1px solid #1d7ca3;
            background: #1d7ca30a;
            border-radius: 4px;

            .room-type {
                font-size: 18px;
            }

            ul {
                padding-left: 0;
                margin-bottom: 0;

                li {
                    list-style-type: none;
                    font-size: 15px;
                    line-height: 18px;
                    color: #0d0d0d;
                    display: inline-block;
                    margin-top: 7px;
                    margin-right: 10px;
                }
            }

            .input-group {
                font-size: 15px;
                margin-bottom: 6px;
                justify-content: space-between;

                label {
                    font-weight: 500;
                }

                span {
                    margin-left: 10px !important;
                }
            }
        }

        .total-amount {
            width: 100%;
            border: 1px solid #d8a306;
            background-color: #d8a30626;
            border-radius: 4px;
            color: #171616;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 20px;

            h5 {
                font-size: 16px;
                margin-bottom: 0;
            }

            span {
                font-size: 20px;
                font-weight: 700;
            }

        }

        .btn-box {
            .btn {
                background: #1d7ca3;
                font-weight: 500;
                color: #fff;
                font-size: 16px;
            }
        }
    }

}

@media screen and (max-width: 576px) {

    .ant-picker-panels {
        display: flex;
        flex-direction: column;
    }

    .strip {

        display: none;
        // padding: 17px 20px;
        // margin-top: 0;

        // h3 {
        //     font-size: 14px;
        //     letter-spacing: 0.4px;
        //     margin-bottom: 0;
        // }
    }

    .about-us {
        margin-bottom: 60px;

        .about {
            .content {
                margin-top: 14px;

                span {
                    display: none;
                }
            }
        }
    }

    .roomSlider {
        margin: 22px 0 30px;
    }

    .services,
    .location,
    .room-accommodation {
        margin-bottom: 30px;
    }

    .services {
        .card {
            padding: 20px;

            .content {
                h3 {
                    margin-bottom: 10px;
                    font-size: 17px;
                    font-weight: 600;
                }

                p {
                    font-size: 15px;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .strip {

        display: none;
    }



}

@media screen and (max-width: 820px) {

    .strip {
        display: none;
    }

    .services,
    .location,
    .room-accommodation {
        margin-bottom: 30px;
    }

    .services {
        .card {
            padding: 20px;

            .content {
                h3 {
                    margin-bottom: 10px;
                    font-size: 17px;
                    font-weight: 600;
                }

                p {
                    font-size: 15px;
                }
            }

            img {
                width: 77px;
            }
        }
    }

    .about-us {
        margin-bottom: 60px;

        .about {
            .content {
                margin-top: 14px;

                span {
                    display: none;
                }
            }
        }
    }

    .roomSlider {
        margin: 20px 0 30px;
    }


}

.privacy-policy {
    color: #0d6efd;
    text-decoration: underline;
    cursor: pointer;
}

.extra-bed .ant-select-selector {
    border: 1px solid #ff9100 !important;
    color: #000;

    .ant-select-selection-search option {
        color: black;
    }
}

::placeholder {
    color: black;
    opacity: 1;
    /* Firefox */
}

::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: black;
}

//   assets/images/slide-2.webp
//   .container-parveen {
//     background-image: url('../../../public/assets/images/slide-2.webp'); 
//     background-size: cover;
//     width: 100%;
//     height: 100vh;
//     position: relative;
//   }

//   .content-parveen {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     padding: 20px;
//     background-color: rgba(255, 255, 255, 0.8); /* Adjust the opacity as needed */
//     backdrop-filter: blur(10px); /* Adjust the blur amount as needed */
//     border-radius: 10px;
//     box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Optional: Add shadow for better visibility */
//   }


.offer_cntr {
    .ticket {
        display: flex;
        font-family: Roboto;
        border: 1px solid #E0E0E0;
        position: relative;
        margin-bottom: 15px;

        &:before {
            content: '';
            width: 35px;
            height: 35px;
            background-color: #fff;
            border: 1px solid #E0E0E0;
            border-top-color: transparent;
            border-left-color: transparent;
            position: absolute;
            transform: rotate(-45deg);
            left: -18px;
            top: 50%;
            margin-top: -16px;
            border-radius: 50%;
        }

        &:after {
            content: '';
            width: 35px;
            height: 35px;
            background-color: #fff;
            border: 1px solid #E0E0E0;
            border-top-color: transparent;
            border-left-color: transparent;
            position: absolute;
            transform: rotate(135deg);
            right: -18px;
            top: 50%;
            margin-top: -16px;
            border-radius: 50%;
        }

        &--start {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            &:before {
                content: '';
                width: 35px;
                height: 35px;
                background-color: #fff;
                border: 1px solid #E0E0E0;
                border-top-color: transparent;
                border-left-color: transparent;
                border-right-color: transparent;
                position: absolute;
                transform: rotate(-45deg);
                left: -18px;
                top: -2px;
                margin-top: -16px;
                border-radius: 50%;
            }

            &:after {
                content: '';
                width: 35px;
                height: 35px;
                background-color: #fff;
                border: 1px solid #E0E0E0;
                border-top-color: transparent;
                border-left-color: transparent;
                border-bottom-color: transparent;
                position: absolute;
                transform: rotate(-45deg);
                left: -18px;
                top: 100%;
                margin-top: -16px;
                border-radius: 50%;
            }

            border-right: 1px dashed #E0E0E0;

            p {
                margin: 0;
            }
        }

        &--center {
            width: 100%;
            padding: 15px;

            &--col {
                height: 95px;
                p {
                    font-family: var(--bs-body-font-family);
                    font-size: 14px;
                }
                .code {
                    margin-bottom: 8px;

                    p {
                        background: #1b8bb9;
                        padding: 2px 5px 2px 2px;
                        border-radius: 6px;
                        color: white;
                        display: inline-block;
                        font-family: "Barlow";
                        font-size: 14px;

                        span {
                            background: white;
                            padding: 0px 3px;
                            border-radius: 4px;
                            color: #1b8bb9;
                            display: inline-block;
                            box-shadow: none;
                            font-family: "Barlow";
                            &::after {
                                display: none;
                            }
                        }
                    }

                    span {
                        white-space: nowrap;
                        background: white;
                        padding: 4px;
                        padding-left: 10px;
                        border-radius: 4px;
                        box-shadow: 2px 0px 2px #ccc;
                        position: relative;
                        font-family: "Barlow";
                        font-size: 14px;

                        &::after {
                            content: "";
                            width: 0;
                            height: 0;
                            border-color: transparent transparent transparent #1b8bb9;
                            border-style: solid;
                            border-width: 8px 0 8px 6px;
                            position: absolute;
                            left: -1px;
                            top: 5px;
                        }
                    }
                }
            }
        }

        &--end {
            padding: 30px 12px;
            background-color: #d8a306;
            display: flex;
            flex-direction: column;
            position: relative;

            &:before {
                content: '';
                width: 35px;
                height: 35px;
                background-color: #fff;
                border: 1px solid #E0E0E0;
                border-top-color: transparent;
                border-right-color: transparent;
                border-bottom-color: transparent;
                position: absolute;
                transform: rotate(-45deg);
                right: -18px;
                top: -2px;
                margin-top: -16px;
                border-radius: 50%;
            }

            &:after {
                content: '';
                width: 35px;
                height: 35px;
                background-color: #fff;
                border: 1px solid #E0E0E0;
                border-right-color: transparent;
                border-left-color: transparent;
                border-bottom-color: transparent;
                position: absolute;
                transform: rotate(-45deg);
                right: -18px;
                top: 100%;
                margin-top: -16px;
                border-radius: 50%;
            }

            &>div {
                &:first-child {
                    flex: 1;

                    i {
                        font-size: 22px;
                        color: white;
                        text-shadow: 1px 1px 1px #000;
                    }
                }

                &:last-child {
                    i {
                        font-size: 22px;
                        color: white;
                        text-shadow: 1px 1px 1px #000;
                    }
                }
            }
        }

    }
}

.responsiveTable {
    .ant-table-content {
        overflow: auto;
        table {
            tr {
                th {
                    white-space: nowrap;
                }
                td{
                    white-space: nowrap;
                }
            }
        }
    }
}