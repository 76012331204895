// $color-theme1: #0c2844;
$color-theme1: #09205c;
$color-theme2: #ffff;
$nav-font: 'Gilda Display',
    serif;

header {
    .top-header {
        background-color: #ebebeb;
        // padding: 5px;

        @media screen and (max-width: 820px) {
            // background-color: #09205c;
            // padding: 10px 8px;
            // display: flex;
            // justify-content: space-around;
            // color: #fff;
            // span{
            //     font-size: 14px;
            // }
            display: none;

        }

        @media screen and (max-width: 768px) {
            span {
                font-size: 16px;
            }

        }

        @media screen and (max-width: 820px) {
            span {
                font-size: 18px;
            }

        }

        @media screen and (max-width: 992px) {
            padding: 10px;
            display: flex;
            justify-content: space-around;

        }

        @media screen and (max-width: 490px) {
            display: none;

        }
    }

    .middle-header {
        position: relative;

        .pattern {
            position: absolute;
            height: 100%;
            width: 100%;
            background-image: url(../../../public/assets/images/pattern.jpg);
            background-position: left top;
            background-size: auto;
            background-repeat: repeat;
            background-attachment: scroll;
            opacity: 0.8;
        }

        .custom-container {
            position: relative;
            z-index: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;


            .menu {
                display: none;

                svg {
                    text-decoration: none;
                    color: #706d6d;
                }

                @media screen and (max-width: 992px) {
                    display: block;
                }
            }

            .location,
            .email {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                max-width: 230px;

                .icon {
                    border: 1px solid #bc9132;
                    border-radius: 50px;
                    display: flex;
                    padding: 6px;
                    margin-right: 8px;

                    i {
                        color: #bc9132;
                        font-size: 21px;
                    }
                }

                span {
                    font-size: 16px;
                }

                @media screen and (max-width: 992px) {
                    display: none;
                }

                @media screen and (max-width: 1199px) {
                    max-width: 220px;

                    span {
                        font-size: 13px !important;
                    }

                }

                @media screen and (max-width: 1400px) {

                    span {
                        font-size: 15px;
                    }
                }

            }


            .navbar-brand {
                padding: 10px 20px 10px 20px;
                width: 220px;


                @media screen and (max-width: 576px) {
                    padding: 10px 0 10px;

                    img {
                        max-width: 155px;
                    }
                }

                @media screen and (max-width: 1400px) {
                    padding: 10px;
                    width: 200px;
                }

            }

            .call {
                display: flex;
                align-items: center;

                svg {
                    color: #bc9132;
                    margin-right: 10px;
                }

                span {
                    font-size: 18px;
                    font-weight: 600;
                    color: #caa85d;
                }

                @media screen and (max-width: 992px) {
                    display: none;
                }

                @media screen and (max-width: 1200px) {
                    span {
                        font-size: 15px !important;
                    }
                }

            }


            .search-room {
                border-radius: 3px;
                font-size: 17px;
                padding: 13px 45px;
                background-color: #d8a306;
                color: #fff;
                font-weight: 700;
                border: 0;
                text-decoration: none;
                margin-left: 30px;

                @media screen and (max-width: 576px) {
                    font-size: 15px;
                }
            }

        }

        @media screen and (max-width: 820px) {
            border-bottom: 1px solid #eee;
        }

    }

    .navbar {
        background-color: $color-theme1;
        padding: 10px 0px;
        transition: top 0.6s;


        .navbar-nav {
            .nav-item {
                margin-right: 10px;

                a {
                    color: #fff;
                    font-family: $nav-font;
                    font-size: 18px;
                    font-weight: 600;
                    letter-spacing: 0.6px;

                    @media screen and (max-width: 768px) {
                        font-size: 15px;
                        text-align: center;
                        font-weight: 500;
                    }

                    @media screen and (max-width: 992px) {
                        font-size: 18px;
                        text-align: center;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    .fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1000;
        background: linear-gradient(180deg, #2098c9 0%, #12749c 56%) !important;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

    }
}

.disabled-button {
    font-size: 17px;
    padding: 13px 30px;
    cursor: not-allowed;
    font-weight: 700;
    border-radius: 3px;
    font-size: 15px;
    padding: 13px 43px;
    margin-top: 34px;
    font-family: "Gilda Display", serif;
}

.login-button {
    .ant-btn-default {
        // background: #ffffff;
        font-size: 14px;
        background: #09205c;
        color: #fff;
        padding: 2px 15px;
        // color: #09205c !important;
        font-family: "Gilda Display", serif;
        font-weight: 600;
        height: 40px !important;
        // border: none;
    }

    .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
        background: #09205c;
        color: #fff;
    }

}

.login-button-scroll {
    .ant-btn-default {
        // background: #ffffff;
        font-size: 14px;
        background: linear-gradient(180deg, #2098c9 0%, #12749c 56%) !important;
        color: #fff;
        padding: 2px 15px;
        font-family: "Gilda Display", serif;
        font-weight: 600;
        height: 40px !important;
        // border: none;
    }

    .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
        background: linear-gradient(180deg, #2098c9 0%, #12749c 56%) !important;
        color: #fff;
    }

}

.phone-icons {
    font-size: 25px;
    margin-top: 10px;
    color: #bc9132;
}

.navbar-heading {
    color: #eda608;
    border-bottom: 2px solid #eda608;
}


// .header-bar {
//     background-color: #09205c;
//     color: white;
//     padding: 20px 0;
//     transition: top 0.3s;
// }

//   .fixed {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     z-index: 1000; 
//   }
