$title-font: 'Gilda Display',
    serif;
$bg-color-theme : #d8a306;

@mixin footer-heading {
    color: #fff;
    font-size: 22px;
    margin-bottom: 25px;
    font-family: $title-font;

    @media screen and (max-width: 576px) {
        font-size: 18px;
        margin-bottom: 12px;
    }
}

footer {
    // background-color: #0c2844;
    background-color: #09205c;

    .footer-main {
        padding: 110px 0 30px;

        .info {

            .navbar-brand {
                img {
                    width: 210px;
                    opacity: 0.99;
                    margin-bottom: 26px;
                }
            }

            h4 {
                @include footer-heading;
            }

            p {
                // text-align: justify;
                margin-bottom: 16px;
                font-size: 16px;
                color: #d5d5d5;
            }

            ul {
                padding: 0;

                li {
                    font-size: 16px;
                    list-style-type: none;
                    line-height: 30px;

                    a {
                        color: #d5d5d5;
                        text-decoration: none;
                        position: relative;

                        &:after {
                            position: absolute;
                            left: 0;
                            bottom: -4px;
                            width: 0;
                            height: 1px;
                            background-color: $bg-color-theme;
                            content: "";
                            -webkit-transition: all 300ms ease;
                            transition: all 300ms ease;
                        }

                        &:hover {
                            &:after {
                                width: 100%;
                            }
                        }
                    }
                }
            }

            .social-links {

                span {
                    margin-bottom: 10px;
                    color: #ddd;
                    letter-spacing: 0.5px;
                }

                ul {
                    display: flex;
                    margin-top: 10px;

                    li {
                        margin-right: 14px;

                        a {
                            background: #cba24b;
                            color: #fff;
                            padding: 8px;
                            display: flex;
                            border-radius: 3px;

                            i {
                                font-size: 21px;
                            }
                        }
                    }
                }
            }
        }

        .contact-info {
            h4 {
                @include footer-heading;
            }

            ul {
                padding: 0;

                li {
                    font-size: 16px;
                    list-style-type: none;
                    line-height: 30px;

                    a {
                        color: #d5d5d5;
                        text-decoration: none;
                        position: relative;
                    }
                }
            }
        }

    }

    .footer-bottom-bar {
        padding: 15px 30px;
        font-size: 15px;
        color: #fff;
        background: linear-gradient(180deg, #2098c9 0%, #12749c 56%) !important;

        p {
            margin-bottom: 0;
            font-weight: 500;
        }
    }
}

@media screen and (max-width:576px) {
    footer {
        .footer-main {
            padding: 30px 15px 10px;

            .info {
                P {
                    font-size: 15px;
                    line-height: 26px;
                }

                .navbar-brand img {
                    width: 165px;
                }

                ul {
                    li {
                        font-size: 15px;
                        line-height: 26px;
                    }
                }
            }
        }

        .footer-bottom-bar {
            padding: 15px;
            font-size: 14px;
            text-align: center;
        }
    }
}

@media screen and (max-width:991px) {
    footer {
        .footer-main {
            padding: 30px 0;
        }
    }
}