.ant-modal .ant-modal-close {

    height: 30px;
    width: 30px;
    text-align: center;
    line-height: 42px;
    padding-top: 2px;
    background: #fff;
    border-radius: 50px;
    border: 1px solid #bfbfbf;
    box-shadow: 0px 0px 4px #bfbfbf;
}


.auth {
    padding: 35px;
    background: #fffdf8;
    border-radius: 20px;
    // background-color: rgba(255, 255, 255, 0.8);
    // backdrop-filter: blur(10px);
    // -webkit-backdrop-filter: blur(10px);





    .ant-select-single {
        height: 56px;
    }

    .main-heading {
        // color: #222222 !important;
        // font-weight: 600;
        // font-size: 28px;
        font-family: "Gilda Display", serif;
        // text-align: center;
        margin-bottom: 1rem;
        color: rgba(94, 6, 5, 0.8117647059);
        position: relative;
        font-size: 33px;
        font-weight: 600;
        letter-spacing: 1px;

        &::after {
            position: absolute;
            content: "";
            width: 45px;
            height: 2px;
            border-radius: 5px;
            background-color: #bd9211;
            top: 100%;
            left: 0;
            // transform: translate(-50%, -50%);

        }
    }

    p {
        color: #444444;
        font-size: 16px;
        font-weight: 500;
        font-weight: bolder;
        font-family: "Gilda Display", serif;
    }

    .ant-input-outlined {
        border-radius: 5px;
        padding: 12px 10px;
        width: 100%;
        border: 1px solid #DEDEDE;
        margin-bottom: 0;
        height: 56px;
        // margin-top: 12px;
    }

    .next-btn {
        background: #d8a306;
        border: none;
        color: #fff;
        border-radius: 5px;
        font-size: 18px;
        font-family: "Barlow";
        font-weight: 600;
        height: 57px;
        padding: 0 12px;
        // margin-top: 40px;
    }

    .next-avg {
        display: flex;
        justify-content: flex-end;

        .anticon svg {
            font-size: 16px;
            margin-top: -6px;
            font-weight: 700;
            margin-left: 24px;
        }
    }

    .ant-input-group .ant-input-group-addon:first-child {
        background: #fff;
        width: 120px !important;
    }

    .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
        color: #ffff;
        border-color: #d8a306;
        background-color: #d8a306;
    }


}

.otp-page {
    .ant-input-outlined {
        font-size: 28px
    }

    .input-otp {
        display: flex;
        gap: 1em;
        justify-content: space-between !important;
    }

    .ant-input-outlined {
        max-width: 65px;
    }


}



.ant-typography {
    min-width: 38px;
}

.arrowcountry {
    .ant-select .ant-select-arrow {
        position: absolute;
        top: 50%;
        inset-inline-start: auto;
        inset-inline-end: -11px;
    }

    .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
        background: #ffffff;
        padding: 0 !important;
    }
}

.create-profile {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}



.user-info {
    margin-left: 10px;
}


.user-info a {
    text-decoration: none;
    color: black;
    font-weight: bold;
}

.user-info p {
    margin: 0;
    font-size: 12px;
    color: gray;
}

.text-truncate {
    width: 100px;
}

.profile-name {
    display: flex;
    align-items: center;
}

.whatsApp {
    .anticon svg {
        display: inline-block;
        background: #25D366;
        border-color: #25D366 !important;
        border-radius: 50px;
        color: #fff;
    }

    .ant-btn-default {
        color: #25D366;

        &:hover {
            color: #25D366 !important;
            border-color: #25D366 !important;
        }
    }
}

.sms {
    .ant-btn-default {
        color: #25D366;

        &:hover {
            color: #25D366 !important;
            border-color: #25D366 !important;
        }
    }
}

.ant-tabs .ant-tabs-tab-btn {
    font-family: "Gilda Display", serif;
    // margin-bottom: 1rem;
    color: rgba(94, 6, 5, 0.8117647059);
    position: relative;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    text-shadow: 0 0 0.25px currentcolor;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-family: "Gilda Display", serif;
    // margin-bottom: 1rem;
    color: rgba(94, 6, 5, 0.8117647059);
    position: relative;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    text-shadow: 0 0 0.25px currentcolor;
}

.booking-hostry-card {
    background: #fffdf8;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.custom-button {
    background-color: #3498db;
    border: none;
    font-family: "Gilda Display", serif;
    color: #fff !important;
    text-align: center;
    font-weight: 600;
    text-decoration: none;
    height: 40px;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-button:hover {
    background-color: #2980b9 !important;

}

.booking-history {
    padding: 20px;
}

.booking-history {
    padding: 20px;
}




// .booking-card {
//     background: #fffdf8 !important;
//     border: 1px solid #ccc;
//     border-right: 0;
//     border-radius: 10px 0 0 10px !important;
//     padding: 15px !important;
//     min-width: 290px !important;
//     box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
// }



// You can define additional styles as needed

.booking-card {
    background-color: #f8f8f8;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s ease;

    .booking-title {
        font-family: "Gilda Display", serif;
        color: rgba(94, 6, 5, 0.8117647059);
        position: relative;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 1px;
        margin-bottom: 0;


        &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 2px;
            border-radius: 5px;
            background-color: #bd9211;
            top: 100%;
            left: 0;
        }

    }

    .info-item {
        margin-bottom: 10px;

        strong {
            font-weight: bold;
            margin-right: 5px;
        }

        span {
            color: #666;
        }
    }
}

.booking-table {
    border-collapse: collapse;
    font-family: Arial, sans-serif;
    width: 100%;

}

th,
td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
}

th {
    background-color: #f2f2f2;
    font-weight: bold;
    text-align: left;
    color: #333;
}

td {
    color: #666;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}

tr:hover {
    background-color: #f2f2f2;
}

// .booking-title {
//     color: #1890ff;
//     font-size: 18px;
//     margin-bottom: 12px;
// }

.ant-checkbox+span {
    padding-inline-start: 8px;
    padding-inline-end: 8px;
    color: rgba(94, 6, 5, 0.8117647059);
    position: relative;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
    text-shadow: 0 0 0.25px currentcolor;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background-color: #ffffff;
}

.ant-checkbox .ant-checkbox-inner {
    border: 1px solid #0000ff78;
}

.download_pdf_btn {
    .ant-btn-primary {
        background: #d8a306;
        border: none;
        color: #fff;
        width: 100%;
        border-radius: 4px;
        font-size: 16px;
        font-family: "Barlow";
        font-weight: 600;
        height: 40px;
        padding: 5px 30px;

        &:hover,
        &:focus {
            background: #d8a306 !important;
        }

        &:disabled,
        &.ant-btn-disabled {
            cursor: not-allowed;
            border-color: #d9d9d9;
            color: rgba(0, 0, 0, 0.25);
            background: rgba(0, 0, 0, 0.04);
            box-shadow: none;

            &:hover,
            &:focus {
                background: rgba(0, 0, 0, 0.04) !important;
                border-color: #d9d9d9;
                color: rgba(0, 0, 0, 0.25);
            }
        }

        &:not(:disabled):hover,
        &:not(:disabled):focus {
            background: darken(#d8a306, 10%);
        }
    }
}

.ant-table-filter-trigger {
    border: 1px solid #0000FF;
    padding: 6px !important;
    border-radius: 10px;
    font-size: 14px !important;
}


.cancellation-button {
    background: #09205c;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    margin-top: 10px;
    text-align: center;

    &:hover {
        color: #fff !important;
        border-color: #09205c !important;
        background: #09205c !important;
    }
}



.cancel_button {
    background-color: #e10d0d;
    color: #fff;
    opacity: 1;
    font-weight: 500;

    &:hover {
        background-color: red !important;
        color: #fff !important;
    }
}

// .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
//     color: #09205c;
//     border-color: #09205c;
//     background: #ffffff;
// }