.hotel_confirm {
    .hotel_confirm_images {
        img {
            width: 100%;
            margin-bottom: 10px;
        }

        .ant-col-md-6 {
            img {
                height: 210px;
                object-fit: cover;
            }
        }
    }

    h2 {
        margin-bottom: 10px;
    }

    h6 {
        font-size: 14px;
        font-weight: normal;
    }

    ul {
        li {
            color: #ff9d00;
        }
    }

    .bg-success {
        background: #07b720;
        color: white;
        padding: 2px 5px;
        border-radius: 5px;
    }

    .btn-primary {
        padding: 8px 15px;
        border-radius: 5px;
        display: inline-block;
        margin-top: 20px;
    }

    .hotel_confirm_cntr {
        position: sticky;
        top: calc(32px + 58px);
        flex-grow: 1;
        align-self: start;
    }

    .Hotel_services {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;

        li {
            flex: 1 1 300px;
            color: #111;
            font-size: 16px;
        }
    }

    .underline {
        text-decoration: underline;
        color: #0062ff;
        font-weight: 600;
        margin-top: 20px;
        display: inline-block;
    }



    .price_cntr {
        background: #e8eaf5;
        height: 100%;
        align-items: end;
        justify-content: end;
        display: flex;
        width: 100%;
        padding: 0 5px 5px 0;
        border-radius: 5px;
    }

}


.cursor-pointer {
    cursor: pointer;
}

.more_photos {
    text-align: center;
    margin-top: 20px;
    /* Adjust as needed */
}

.more_photos_link {
    display: inline-block;
    position: relative;
    padding: 8px 16px;
    border: 2px solid #007bff;
    color: #000;
    text-decoration: none;
    border-radius: 4px;
    font-family: "Gilda Display", serif;
    font-weight: 600;
    font-size: 16px;
    transition: transform 0.2s, border-color 0.2s, color 0.2s, background-color 0.2s;

    &:hover {
        background: linear-gradient(180deg, #2098c9 0%, #12749c 56%) !important;
        color: #fff !important;
    }
}


.more_photos_link:hover {
    transform: translateY(-2px);
    border-color: #0056b3;
    color: #0056b3;
}

.more_photos_icon {
    vertical-align: middle;
    margin-right: 8px;
}

.more_photos_text {
    vertical-align: middle;
}

.hover14 {
    position: relative;
    overflow: hidden;

    figure {
        position: relative;
        overflow: hidden;
    }

    figure::before {
        position: absolute;
        top: 0;
        left: -75%;
        z-index: 2;
        display: block;
        content: '';
        width: 50%;
        height: 100%;
        background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
        background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
        transform: skewX(-25deg);
        transition: left 0.75s;
    }

    &:hover figure::before {
        left: 125%;
    }
}
